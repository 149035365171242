<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">

    </div>

  </Layout>
</template>
<script>

import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../app.config";

export default {
  name:'Dashboard EASY UX',
  page: {
    title: "Dashboard EASY UX",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Dashboard EASY UX",
      items: [
        {
          text: "Dashboard EASY UX",
          href: "/",
        },
        {
          text: "Dashboard EASY UX",
          active: true,
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
};
</script>


